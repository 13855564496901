import { css, useTheme } from "@emotion/react";
import { Fire, Gear, SquaresFour } from "@phosphor-icons/react";
import { Navigation } from "./Navigation";

import { dragToSelectAndDeselectClass } from "../../lite/views/Collection/DragAndDrop/useDragToSelect";

interface SidebarContentProps {
  collapsed: boolean;
  merchantId: string;
  onNavigate?: () => void;
  isMobile: boolean;
}

export const SidebarContent = (props: SidebarContentProps) => {
  const theme = useTheme();

  return (
    <div
      css={css`
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin-top: 44px;
        gap: 12px;
        flex: 1 0 0;
        align-self: stretch;
        background: ${theme.colors.bg["white-0"]};
      `}
      {...(!props.isMobile && { className: dragToSelectAndDeselectClass })}
    >
      {/*<MenuGroupHeader title="Main" />*/}
      <Navigation
        items={[
          {
            collapsed: props.collapsed,
            indicator: false,
            leftIcon: ({ css }) => <SquaresFour css={css} />,
            title: "Collections",
            href: `/${props.merchantId}/collections`,
            onClick: props.onNavigate,
          },
          {
            collapsed: props.collapsed,
            indicator: false,
            leftIcon: ({ css }) => <Fire css={css} />,
            title: "Boosts",
            href: `/${props.merchantId}/boost-and-bury`,
            onClick: props.onNavigate,
          },
          {
            collapsed: props.collapsed,
            indicator: false,
            leftIcon: ({ css }) => <Gear css={css} />,
            title: "Settings",
            href: `/${props.merchantId}/settings`,
            onClick: props.onNavigate,
          },
        ]}
      />
    </div>
  );
};
